import React, { Component } from 'react'
import { Link } from 'gatsby' 
import thankviewLogo from '../images/thankview-logo.png'

import '../assets/layout.less'

class Header extends Component {

	render() {
		return (
			<div id="header" onClick={this.props.sidebarClickHandler}>
				<span className="logo-wrapper"><i className="fa fa-align-justify"></i></span>
				<span className="logo-wrapper"><Link to="/"><img className="logo" alt="logo" src = {thankviewLogo} /></Link></span>
			</div>
		); 
	}

}

export default Header;