import React, { Component } from 'react'
import { Link } from 'gatsby'

//styles
import '../assets/layout.less'; 

class ScrollableLink extends Component {
	constructor(props){
		super(props); 
		
		this.smoothScroll = this.smoothScroll.bind(this); 
		this.easingFunc = this.easingFunc.bind(this);
	}

	easingFunc = (t, b, c, d) => {
		t /= d/2;
		if (t < 1) return c/2*t*t + b;
		t--;
		return -c/2 * (t*(t-2) - 1) + b;
	};

	smoothScroll = (e) => {
		e.preventDefault(); 

		var href = e.target.href; 
		var id = href.split('#')[1]; 

		var pos = document.getElementById(id).getBoundingClientRect(); 

		var headerHeight = document.getElementById('header').getBoundingClientRect().height; 

		var start = (document.body.scrollTop || document.documentElement.scrollTop);

		var time = 0; 
		
		var end = pos.y - headerHeight; 
		var _this = this; 

		var interval = setInterval(function() { 

			var value = _this.easingFunc(time, start, end, 1000); 

			window.scrollTo(0, value); 	

			time+=16; 

			if(document.documentElement.scrollTop === end || time >= 1000) {
				clearInterval(interval); 
			}
		}, 16); 
	}

	render() {
		return (
			<Link className="link-default" to={this.props.to} onClick={this.smoothScroll}>{this.props.children}</Link>
		)
	}

}

export default ScrollableLink; 
