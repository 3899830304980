import React, { Component } from 'react'
import ScrollabeLink from './scrollableLink'


//styles
import '../assets/layout.less'; 

class Sidebar extends Component {
	constructor(props) {
		super(props);  

		this.state = {  
		}

		this.closeOnMobile = this.closeOnMobile.bind(this); 
	}

	componentWillMount = () => { 
		if(this.props.data) {
			var headersOpen = this.props.data.reduce((acc, section, i) => {
				acc[`sec_${i}`] = true; 
				return acc;
			}, {}); 

			this.setState(headersOpen)
		}
	}

	closeOnMobile = (e) => {
		e.stopPropagation(); 
		if (window.innerWidth <= 650) {
			this.props.sidebarClickHandler(); 
		}
	}; 

	toggleSubSection = (sec) => {
		this.setState({
			[sec] : !this.state[sec]
		}); 
	}; 

	render() {
		var openClass = (this.props.active) ? 'open' : ''; 
		const data = this.props.data;
		
		return (
			<div className={`side-bar ${openClass}`}>
				<ul className="list">
				 	{data && data.map((section, i) =>
	         	<li key={i} onClick={this.toggleSubSection.bind(this, `sec_${i}`)}>{section.name} <i className="fa fa-caret-down"></i>
	         		<ul className={this.state[`sec_${i}`] ? 'show' : 'hide'}>
	            {section.contents.map((content, j) =>  	
									<li key={content.id} className="list list-item" onClick={this.closeOnMobile}><ScrollabeLink to={`#sec_${content.id}`}>{content.data}</ScrollabeLink></li>			
	            )}
	            </ul>
	          </li>
        	)}
				</ul>
			</div>
		)
	}
}

export default Sidebar;