import React from 'react'

import '../assets/layout.less'

const Footer = () => (
	<div className="footer">
		<p>For any questions, email us at <a href="mailto:support@thankview.com" className="tv-link">support@thankview.com</a>.</p>
	</div>

)

export default Footer;