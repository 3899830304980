import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from './header'
import Sidebar from './sidebar'
import Footer from './footer'

//favicon 
import fav from '../images/favicon.png'

import '../assets/layout.less'

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar: false
    }

    this.sidebarClickHandler = this.sidebarClickHandler.bind(this);
  }

  sidebarClickHandler = (e) => {
    this.setState({
      sidebar: !this.state.sidebar
    });
  }

  render() {
    return (
      <div>
        <Helmet
          title="SFTP Documentation"
          meta={[
            { name: "description", content: "You can interact with your data in ThankView via SFTP (a secure file transfer protocol) which allows you to transfer and access data through a secure system." }
          ]}
          link={[
            { rel: "stylesheet", href: "//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" },
            { rel: "icon", type: "image/png", href: `${fav}` }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header sidebarClickHandler={this.sidebarClickHandler} />
        <div className="layout">
          <Sidebar sidebarClickHandler={this.sidebarClickHandler} active={this.state.sidebar} data={this.props.data} />
          <div className={`contents`}>
            {this.props.children}
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout
